import React from "react";
import PropTypes from "prop-types";

import { Button, Carousel } from "react-bootstrap";

import PageSection from "components/PageSection";
import Image from "components/Image";

import "./PhotoCarousel.scss";

const PhotoCarousel = ({ className, frontmatter }) => {

    if (!frontmatter) {
        return null;
    }

    const { anchor, photos } = frontmatter;

    return (
        <PageSection className={className} id={anchor}>
            <Carousel>
                {photos.map((photo) => (
                    <Carousel.Item key={photo.fileName} interval={7000}>
                        <Image className="d-block"
                            fileName={photo.fileName}
                            alt={photo.alt}
                        />
                        <Carousel.Caption style={{ color: photo.captionColor }}>
                            <Button className="btn-no-hover" variant="primary" size="sm" type={null}>{photo.caption}</Button>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))
                }
            </Carousel >
        </PageSection>
    )
};

PhotoCarousel.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

PhotoCarousel.defaultProps = {
    className: null,
    frontmatter: null,
};

export default PhotoCarousel;
