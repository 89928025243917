import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";

import "./TeamMember.scss";
import CircleIcon from "./CircleIcon";

const TeamMember = ({
  imageFileName,
  imageAlt,
  header,
  subheader,
  telephone,
  email
}) => {
  const phonePart = telephone ? <CircleIcon href={`tel:${telephone}`} iconName="PhoneIcon" /> : null;
  const mailPart = email ? <CircleIcon href={`mailto:${email}`} iconName="EnvelopIcon" /> : null;

  return (
    <div className="team-member">
      <Image
        className="mx-auto circle rounded-circle"
        imgStyle={{ borderRadius: "100%" }}
        fileName={imageFileName}
        alt={imageAlt || header || subheader}
      />
      <h4>{header}</h4>
      <p className="text-muted">{subheader}</p>
      <div>
        {phonePart}
        {mailPart}
      </div>
    </div>
  );
};

TeamMember.propTypes = {
  imageFileName: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  header: PropTypes.string,
  subheader: PropTypes.string,
  telephone: PropTypes.string,
  email: PropTypes.string,
};

TeamMember.defaultProps = {
  imageAlt: null,
  header: "",
  subheader: "",
  telephone: "",
  email: "",

};

export default TeamMember;
