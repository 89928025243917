import React from "react";
import PropTypes from "prop-types";

import { Row, Col, Button } from "react-bootstrap";

import ServiceItem from "components/ServiceItem";
import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import AddToCalendar from "components/AddToCalendar";

const Ceremony = ({ className, frontmatter }) => {
  const [showAddToCalendar, setShowAddToCalendar] = React.useState(false);

  const handleShowAddToCalendar = () => setShowAddToCalendar(!showAddToCalendar);

  if (!frontmatter) {
    return null;
  }

  const { anchor, header: rootHeader, subheader: rootSubHeader, info, calendarEvents } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row>
        <SectionHeader header={rootHeader} subheader={rootSubHeader} />
      </Row>
      <Row className="text-center">
        {info.map((service) => (
          <Col md={4} key={service.header}>
            <ServiceItem {...service} />
          </Col>
        ))}
      </Row>
      <Row className="justify-content-center">
        <Button variant="primary" className="text-uppercase" onClick={handleShowAddToCalendar}>
          Añadir al calendario
        </Button>
        <AddToCalendar events={calendarEvents} show={showAddToCalendar} onHide={handleShowAddToCalendar} />
      </Row>
    </PageSection >
  );
};

Ceremony.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Ceremony.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Ceremony;
