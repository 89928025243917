import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image";
import { Col, Row } from "react-bootstrap";
import MapDetailDialog from "components/MapDetailDialog";

const Location = ({ imageFileName, googleMapsRef }) => {
  const [showDetail, setShowDetail] = React.useState(false);
  const handleShowDetail = React.useCallback(() => {
    setShowDetail(true);
  }, []);
  const handleHideDetail = React.useCallback(() => {
    setShowDetail(false);
  }, []);

  const height = imageFileName === "" ? "450px" : "100%";

  return (
    <Row className="justify-content-center">  
        <iframe src={googleMapsRef}
          title="Google Maps"
          width="100%"
          height={height}
          style={{ border: 0 }}
          loading="lazy" />
    </Row>
  )
};

Location.propTypes = {
  imageFileName: PropTypes.string,
  googleMapsRef: PropTypes.string.isRequired,
};

Location.defaultProps = {
  imageFileName: ""
};

export default Location;
