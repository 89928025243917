import makeFAIcon from "utils/makeFAIcon";

import { faCalendarDay, faClock, faHotel, faCommentMedical, faBus, faMapMarkedAlt } from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faMicrosoft, faYahoo } from "@fortawesome/free-brands-svg-icons";

export const MapIcon = makeFAIcon(faMapMarkedAlt);
export const DateIcon = makeFAIcon(faCalendarDay);
export const TimeIcon = makeFAIcon(faClock);
export const BusIcon = makeFAIcon(faBus);
export const HotelIcon = makeFAIcon(faHotel);
export const AllergiesIcon = makeFAIcon(faCommentMedical);
export const GoogleIcon = makeFAIcon(faGoogle);
export const OutlookIcon = makeFAIcon(faMicrosoft);
export const YahooIcon = makeFAIcon(faYahoo);
