import React from "react";
import PropTypes from "prop-types";

import { Modal, Row, Button } from "react-bootstrap";
import { useStaticQuery, graphql } from "gatsby";

import AddToCalendarItem from "components/AddToCalendarItem";


const AddToCalendar = ({ events, onHide, ...restProps }) => {
    const icsFiles = useStaticQuery(graphql`
    {
        allFile(filter: {relativePath: {regex: "/.*\\/calendar\\/.*/"}}) {
          edges {
            node {
              publicURL
              name
            }
          }
        }
      }
    `)
    return (
        <Modal
            {...restProps}
            onHide={onHide}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="text-center"
                    style={{ marginLeft: "25%", marginRight: "10%" }}>
                    Elige tu calendario
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {events.map((event) => (
                    <Row key={event.text} className="justify-content-center">
                        <AddToCalendarItem href={event.href} icon={event.icon} text={event.text} />
                    </Row>
                ))}


                {icsFiles.allFile.edges.map((file) => {
                    return (
                        <Row key={`ics-${file.node.name}`} className="justify-content-center">
                            <Button variant="link" href={file.node.publicURL}>
                                Archivo ICS{icsFiles.allFile.edges.length > 1 ? ` - + ${file.node.name}` : ""}
                            </Button>
                        </Row>
                    )
                })}

            </Modal.Body>
        </Modal>
    );
};

AddToCalendar.propTypes = {
    events: PropTypes.array,
    onHide: PropTypes.func,
};

AddToCalendar.defaultProps = {
    events: [],
    onHide: null,
};

export default AddToCalendar;