import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row, Col } from "react-bootstrap";
import PageSection from "components/PageSection";
import Location from "components/Location";

const Locations = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, locations } = frontmatter;

  return (
    <PageSection className={clsx("py-5", className)} id={anchor}>
      <Row>
        {locations.map((location) => (
          <Col sm={12} md={6} key={location.imageFileName}>
            <Location googleMapsRef={location.googleMapsRef} />
          </Col>
        ))}
      </Row>
    </PageSection>
  );
};

Locations.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Locations.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Locations;
