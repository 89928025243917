import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import SectionHeader from "components/SectionHeader";
import PageSection from "components/PageSection";
import Image from "components/Image";

import "./Gifts.scss";


const Gifts = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, header, subheader, account, giftImage } = frontmatter;

    return (
        <PageSection className={className} id={anchor}>
            <SectionHeader header={header} subheader={subheader} />
            <Row className="section-header text-center col-lg-12">
                <h3 className="section-subheading text-muted invitacion">La tarjeta de la invitación es plantable y podéis ver las 
                instrucciones <a href="https://sheedostudio.com/usaryplantar/" target="_blank" rel="noreferrer">aquí</a></h3>
            </Row>
            <Row className="text-center">
                <Col xl={{ span: 6, offset: 3 }}
                    lg={{ span: 6, offset: 3 }}
                    md={{ span: 8, offset: 2 }}
                    sm={{ span: 11, offset: 1 }}
                    xs={{ span: 12 }}
                >
                    <h3 className="account">{account}</h3>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <Col className="justify-content-center" xl={{ span: 6, offset: 3 }}
                    lg={{ span: 6, offset: 3 }}
                    md={{ span: 6, offset: 2 }}
                    sm={{ span: 6 }}
                    xs={{ span: 8 }}
                >
                    <Image
                        className="gift-image"
                        fileName={giftImage}
                        alt={giftImage}
                    />
                </Col>
            </Row>
        </PageSection >
    );
};

Gifts.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Gifts.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Gifts;
