import React from "react";
import PropTypes from "prop-types";

import { Modal } from "react-bootstrap";
import Image from "components/Image";

const MapDetailDialog = ({
    onHide,
    imageFileName,
    imageAlt,
    ...restProps
}) => {
    return (
        <Modal
            {...restProps}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <Image className="img-fluid d-block mx-auto" fileName={imageFileName} alt={imageFileName} />
            </Modal.Body>
        </Modal>
    );
};

MapDetailDialog.propTypes = {
    onHide: PropTypes.func,
    imageFileName: PropTypes.string,
    imageAlt: PropTypes.string,
};

MapDetailDialog.defaultProps = {
    onHide: null,
    imageFileName: "",
    imageAlt: null,
};

export default MapDetailDialog;
