import React from "react";
import PropTypes from "prop-types";

import { Row, Col } from "react-bootstrap";

import Icon from "components/Icon";
import PageSection from "components/PageSection";
import ContactItem from "components/ContactItem";

const Contact = ({ className, frontmatter }) => {
  if (!frontmatter) {
    return null;
  }

  const { anchor, header, subheader, email, email2, contacts } = frontmatter;

  return (
    <PageSection className={className} id={anchor}>
      <Row className="justify-content-center">
        <Col lg={8} className="text-center">
          <h2 className="mt-0">{header}</h2>
          <p className="text-muted mb-5">{subheader}</p>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={10} className="text-center">
          <h4 className="text-primary">¡Contacta con nosotros lo antes posible si aún no has confirmado!</h4>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <p className="text-muted mb-2">Fecha límite: 12 de Octubre de 2022</p>
      </Row>
      <hr className="divider my-4" />
      <Row>
        {contacts.map((contact) => (
          <Col lg={4} className="ml-auto text-center" key={contact.name}>
            <ContactItem {...contact} />
          </Col>
        ))}
        <Col lg={4} className="mr-auto text-center">
          <Icon iconName="EnvelopIcon" size="3x" className="text-muted mb-3" />
          <h5>Julia &amp; Arturo</h5>
          <a className="d-block" href={`mailto:${email}`}>
            {email}
          </a>
          <a className="d-block" href={`mailto:${email2}`}>
            {email2}
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  frontmatter: PropTypes.object,
};

Contact.defaultProps = {
  className: null,
  frontmatter: null,
};

export default Contact;
