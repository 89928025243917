import React from "react";
import PropTypes from "prop-types";

import { Button } from "react-bootstrap";

import Icon from "components/Icon";


const AddToCalendarItem = ({ href, icon, text }) => {

    return (
        <Button
            target="_blank"
            variant="link"
            href={href}>
            <Icon iconName={icon} /> &nbsp;&nbsp;{text}
        </Button>
    );
};

AddToCalendarItem.propTypes = {
    href: PropTypes.string,
    icon: PropTypes.string,
    text: PropTypes.string,
};

AddToCalendarItem.defaultProps = {
    href: "",
    icon: "",
    text: "",
};

export default AddToCalendarItem;