import React from "react";
import PropTypes from "prop-types";

import Icon from "components/Icon";

const ContactItem = ({ name, telephone }) => {

    return (
        <>
            <Icon iconName="PhoneIcon" size="3x" className="text-muted mb-3" />
            <h5>{name}</h5>
            <a className="d-block" href={`tel:${telephone}`}>
                {telephone}
            </a>
        </>
    );
};

ContactItem.propTypes = {
    name: PropTypes.string,
    telephone: PropTypes.string,
};

ContactItem.defaultProps = {
    name: null,
    telephone: null,
};

export default ContactItem;
